
import { defineComponent } from 'vue'
import MotifClass from '@/types/class'
import Course from '@/types/course'
import utils from '@/utils/utils'
import AdminNav from '@/components/AdminNav.vue'
const emptyClass: MotifClass = {
  id: 0,
  courseId: 0,
  courseName: '',
  courseDescription: '',
  createdAt: '',
  updatedAt: '',
  price: 0,
  dates: [],
  startTime: '',
  stopTime: '',
  status: '',
  capacity: 0,
  enrollment: 0,
  location: '',
  address: '',
  notes: '',
  studentAgeMinimum: 8,
  studentAgeMaximum: 18
}

export default defineComponent({
  components: { AdminNav },
  data () {
    return {
      waiting: false,
      motifClass: emptyClass,
      courses: [] as Course[]
    }
  },
  async mounted () {
    const response = await this.$api.listCourses()
    this.courses = response.courses || []
    const classId = parseInt(this.$route.params.id as string)
    if (!isNaN(classId)) {
      const response = await this.$api.getClass(classId)
      this.motifClass = response.class || emptyClass
    }
  },
  methods: {
    async saveClass () {
      if (this.motifClass.dates.length < 1) {
        this.$store.commit('error', { error: 'Add at least one date.', type: 'ClassRequiresDate' })
        return
      }
      this.waiting = true
      let response
      if (this.motifClass.id) {
        response = await this.$api.updateClass(this.motifClass)
      } else {
        response = await this.$api.createClass(this.motifClass)
      }
      if (response.class) {
        this.waiting = false
        if (!this.motifClass.id) {
          return this.$router.replace({ name: 'admin_class', params: { id: response.class.id } })
        }
        this.motifClass = response.class
      }
    },
    async deleteClass () {
      if (!confirm('Are you sure you want to delete this class?')) {
        return
      }
      let success = true
      if (this.motifClass.id) {
        const response = await this.$api.deleteClass(this.motifClass.id)
        if (!response.success) {
          success = false
        }
      }
      if (success) {
        return this.$router.replace({ name: 'admin_class_list' })
      }
    },
    addDate () {
      this.motifClass.dates.push({ date: '' })
    },
    removeDate (index: number) {
      if (!confirm('Are you sure you want to remove this date?')) {
        return
      }
      this.motifClass.dates.splice(index, 1)
    },
    dateFormat (dateString: string) {
      return utils.dateStringToDateInputValue(dateString)
    },
    setDate (event: Event, index: number) {
      const target = event.target
      if (target instanceof HTMLInputElement) {
        const dateString = utils.dateInputValueToDateString(target.value)
        if (!dateString) {
          return
        }
        this.motifClass.dates.splice(index, 1, { date: dateString })
      }
    },
    timeFormat (time: string) {
      const date = new Date(time)
      if (isNaN(date.getTime())) {
        return ''
      }
      return utils.dateToTimeInputValue(date)
    },
    setStartTime (event: Event) {
      const target = event.target
      if (target instanceof HTMLInputElement) {
        const time = utils.timeInputValueToTimeString(target.value)
        if (!time) {
          return
        }
        this.motifClass.startTime = time
      }
    },
    setStopTime (event: Event) {
      const target = event.target
      if (target instanceof HTMLInputElement) {
        const time = utils.timeInputValueToTimeString(target.value)
        if (!time) {
          return
        }
        this.motifClass.stopTime = time
      }
    }
  }
})

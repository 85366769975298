<template>
  <div class="col-lg-2 col-sm-12 mb-3">
    <ul class="list-group">
      <li class="list-group-item">
        <router-link :to="{name: 'admin_course_list'}">Courses</router-link>
      </li>
      <li class="list-group-item">
        <router-link :to="{name: 'admin_class_list'}">Classes</router-link>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
})
</script>

<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <admin-nav />
      <div class="col-lg-10 col-sm-12">
        <div class="container">
          <h4 class="mb-3">Manage class: {{ motifClass.courseName ? `${motifClass.courseName} at ${motifClass.location}` : 'new' }}</h4>
          <form @submit.prevent="saveClass()">
            <div class="form-group mb-3">
              <label for="courseId">Course</label>
              <select class="form-select" name="courseId" id="courseId" v-model="motifClass.courseId" required>
                <option v-for="(course, index) in courses" :key="index" :value="course.id">{{ course.name }}</option>
              </select>
            </div>
            <div class="row">
              <div class="col-lg col-sm-12">
                <div class="form-group mb-3">
                  <label for="price">Price</label>
                  <div class="input-group">
                    <span class="input-group-text">$</span>
                    <input class="form-control" type="number" name="price" id="price" v-model="motifClass.price" min="1" required>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-3">
                  <label for="startTime">Start time</label>
                  <input class="form-control" type="time" name="startTime" id="startTime" :value="timeFormat(motifClass.startTime)" @change="setStartTime($event)" required>
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-3">
                  <label for="stopTime">Stop time</label>
                  <input class="form-control" type="time" name="stopTime" id="stopTime" :value="timeFormat(motifClass.stopTime)" @change="setStopTime($event)" required>
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-3">
                  <label for="status">Status</label>
                  <select class="form-select" name="status" id="status" v-model="motifClass.status" required>
                    <option value="pending">Pending</option>
                    <option value="active">Active</option>
                    <option value="closed">Closed</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-3">
                  <label for="price">Capacity</label>
                  <input class="form-control" type="number" name="capacity" id="capacity" v-model="motifClass.capacity" min="1" required>
                </div>
              </div>
            </div>
            <div class="form-group mb-3">
              <label for="location">Location</label>
              <input class="form-control" type="text" name="location" id="location" v-model="motifClass.location" required>
            </div>
            <div class="row">
              <div class="col-lg d-flex flex-column">
                <div class="form-group mb-3 d-flex flex-column flex-grow-1" d-flex flex-column>
                  <label for="address">Address</label>
                  <textarea class="form-control flex-grow-1" name="address" id="address" cols="30" rows="3" v-model="motifClass.address" required></textarea>
                </div>
              </div>
              <div class="col-lg d-flex flex-column">
                <div class="form-group mb-3 d-flex flex-column flex-grow-1">
                  <label for="notes">Class notes</label>
                  <textarea class="form-control flex-grow-1" name="notes" id="notes" cols="30" rows="3" v-model="motifClass.notes"></textarea>
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-3">
                  <label for="studentAgeMinimum">Minimum age of student</label>
                  <input type="number" min="0" class="form-control" name="studentAgeMinimum" id="studentAgeMinimum" v-model="motifClass.studentAgeMinimum">
                </div>
                <div class="form-group mb-3">
                  <label for="studentAgeMaximum">Maximum age of student</label>
                  <input type="number" min="0" class="form-control" name="studentAgeMaximum" id="studentAgeMaximum" v-model="motifClass.studentAgeMaximum">
                </div>
              </div>
            </div>
            <h5>Dates</h5>
            <div class="row">
              <div class="col-lg-3" v-for="(date, index) in motifClass.dates" :key="index">
                <div class="input-group mb-3">
                  <input class="form-control" type="date" :value="dateFormat(date.date)" @change="setDate($event, index)" :name="'date' + index" :id="'date' + index" required>
                  <button class="btn btn-outline-danger" @click.prevent="removeDate(index)">X</button>
                </div>
              </div>
              <div class="col"><button class="btn btn-primary" @click.prevent="addDate()">Add date</button></div>
            </div>
            <div class="d-flex align-items-center justify-content-between mt-3">
              <button class="btn btn-success" type="submit" :disabled="waiting">Save class</button>
              <button class="btn btn-danger" type="button" @click="deleteClass()">Delete class</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MotifClass from '@/types/class'
import Course from '@/types/course'
import utils from '@/utils/utils'
import AdminNav from '@/components/AdminNav.vue'
const emptyClass: MotifClass = {
  id: 0,
  courseId: 0,
  courseName: '',
  courseDescription: '',
  createdAt: '',
  updatedAt: '',
  price: 0,
  dates: [],
  startTime: '',
  stopTime: '',
  status: '',
  capacity: 0,
  enrollment: 0,
  location: '',
  address: '',
  notes: '',
  studentAgeMinimum: 8,
  studentAgeMaximum: 18
}

export default defineComponent({
  components: { AdminNav },
  data () {
    return {
      waiting: false,
      motifClass: emptyClass,
      courses: [] as Course[]
    }
  },
  async mounted () {
    const response = await this.$api.listCourses()
    this.courses = response.courses || []
    const classId = parseInt(this.$route.params.id as string)
    if (!isNaN(classId)) {
      const response = await this.$api.getClass(classId)
      this.motifClass = response.class || emptyClass
    }
  },
  methods: {
    async saveClass () {
      if (this.motifClass.dates.length < 1) {
        this.$store.commit('error', { error: 'Add at least one date.', type: 'ClassRequiresDate' })
        return
      }
      this.waiting = true
      let response
      if (this.motifClass.id) {
        response = await this.$api.updateClass(this.motifClass)
      } else {
        response = await this.$api.createClass(this.motifClass)
      }
      if (response.class) {
        this.waiting = false
        if (!this.motifClass.id) {
          return this.$router.replace({ name: 'admin_class', params: { id: response.class.id } })
        }
        this.motifClass = response.class
      }
    },
    async deleteClass () {
      if (!confirm('Are you sure you want to delete this class?')) {
        return
      }
      let success = true
      if (this.motifClass.id) {
        const response = await this.$api.deleteClass(this.motifClass.id)
        if (!response.success) {
          success = false
        }
      }
      if (success) {
        return this.$router.replace({ name: 'admin_class_list' })
      }
    },
    addDate () {
      this.motifClass.dates.push({ date: '' })
    },
    removeDate (index: number) {
      if (!confirm('Are you sure you want to remove this date?')) {
        return
      }
      this.motifClass.dates.splice(index, 1)
    },
    dateFormat (dateString: string) {
      return utils.dateStringToDateInputValue(dateString)
    },
    setDate (event: Event, index: number) {
      const target = event.target
      if (target instanceof HTMLInputElement) {
        const dateString = utils.dateInputValueToDateString(target.value)
        if (!dateString) {
          return
        }
        this.motifClass.dates.splice(index, 1, { date: dateString })
      }
    },
    timeFormat (time: string) {
      const date = new Date(time)
      if (isNaN(date.getTime())) {
        return ''
      }
      return utils.dateToTimeInputValue(date)
    },
    setStartTime (event: Event) {
      const target = event.target
      if (target instanceof HTMLInputElement) {
        const time = utils.timeInputValueToTimeString(target.value)
        if (!time) {
          return
        }
        this.motifClass.startTime = time
      }
    },
    setStopTime (event: Event) {
      const target = event.target
      if (target instanceof HTMLInputElement) {
        const time = utils.timeInputValueToTimeString(target.value)
        if (!time) {
          return
        }
        this.motifClass.stopTime = time
      }
    }
  }
})
</script>
